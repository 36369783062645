import { Controller } from "@hotwired/stimulus"
import * as bootstrap from 'bootstrap'

// Connects to data-controller="carousel"
export default class extends Controller {
  static targets = [ "carousel" ]

  connect() {
    console.log(this.carouselTarget)
    const carousel = new bootstrap.Carousel(this.carouselTarget, {
      interval: 4000
    })
  }
}
